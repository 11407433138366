import CTAPage from "components/landing-pages/CTA/CTAPage";
import React, { useState } from "react";

const backLink = "/lp/surveillance-d/";

export default function CTAPage2() {
  const [unLockVideo, setUnlockVideo] = useState(false);
  return (
    <CTAPage
      title="See How Rhombus Improves Site Security"
      blurb="Watch and see how you can monitor all locations, automatically identify security events, and resolve incidents in real time with Rhombus."
      formId="2df511af-4e72-46b4-bd7f-95c8136a8bb3"
      formTitle="Unlock Instant Demo"
      formBlurb="Take a quick but feature-packed tour of Rhombus’ Physical Security."
      formLastStepTitle="Unlock Instant Demo"
      formLastStepText="Tell us a little more about yourself."
      successTitle={"Enjoy Our Demo"}
      successMessage={"Someone one from Rhombus will reach out soon."}
      backLink={backLink}
      videoUrl={
        "https://prodapp2stack-core1mediaassetss3bucket-npqqyyhjtsvh.s3.us-west-2.amazonaws.com/rhombus-demo.mp4"
      }
      setOnFormCompleteBoolean={setUnlockVideo}
      formCompleteBoolean={unLockVideo}
    />
  );
}
